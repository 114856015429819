import {Link} from 'react-router-dom';

import Header from '../include/Header';

import Footer from '../include/Footer';

// import { useState } from 'react';

import axios from 'axios';
import validator from 'validator';

import React, { useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

import ImageCropModal from './ImageCropModal';

const mgtop={
    marginTop: '112px'
};

function Register() {

    const [progress, setProgress] = useState(0);

    // const baseurl="https://www.safalata.in/safalatalara";

    let [name,setName]=useState("");
    let [dob,setDob]=useState("");
    let [phone,setPhone]=useState("");
    let [email,setEmail]=useState("");
    let [city,setCity]=useState("");
    let [qualification,setQualification]=useState("");
    let [extra,setExtra]=useState("");
    let [password,setPassword]=useState("");
    let [password2,setPassword2]=useState("");

    let [studentmode,setStudentmode]=useState(1);
    let [isstudentmode,setIsstudentmode]=useState(false);

    let [nameerr,setNameerr]=useState("");
    let [doberr,setDoberr]=useState("");
    let [phoneerr,setPhoneerr]=useState("");
    let [emailerr,setEmailerr]=useState("");
    let [cityerr,setCityerr]=useState("");
    let [qualificationerr,setQualificationerr]=useState("");
    let [extraerr,setExtraerr]=useState("");
    let [passworderr,setPassworderr]=useState("");

    let [docerr,setDocerr]=useState("");
    let [imgerr,setImgerr]=useState("");

    let [img,setImg]=useState(null);

    let [doc,setDoc]=useState(null);

    let [isquali,setIsquali]=useState(false);
    let [isextra,setIsextra]=useState(false);

    let [isbtn,setIsbtn]=useState(true);
    let [ischeck,setIscheck]=useState(false);

    let [msg,setMsg]=useState("");
    let [msgcode,setMsgcode]=useState("");
    let [msgcolor,setMsgcolor]=useState("");

    let [waitmsg,setWaitmsg]=useState("");

    let [mapassingyrlabel,setMapassingyrlabel]=useState("");
    let [mapassingyr,setMapassingyr]=useState("");
    let [ismapassing,setIsmapassing]=useState(true);

    let [extrapassingyrlabel,setExtrapassingyrlabel]=useState("");
    let [extrapassingyr,setExtrapassingyr]=useState("");
    let [isextrapassing,setIsextrapassing]=useState(true);
        
    let [loader,setLoader]=useState(false);

    //CONVERT URL TO BLOB
    const urlToBlob = async (url) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Failed to fetch the file.');
          }
          const blob = await response.blob();
          return blob;
        } catch (error) {
          console.error('Error:', error);
        }
    };
    //CONVERT URL TO BLOB

    // CROPPING IMAGE
    const [imageSrc, setImageSrc] = useState(null);
    const [open, setOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const handleFileChange = (e) => {
       const file = e.target.files[0];
       const reader = new FileReader();
       reader.onload = () => {
          setImageSrc(reader.result);
          setOpen(true);
       };
       reader.readAsDataURL(file);
    };

    const handleCropComplete = async (croppedImage) => {
        setCroppedImage(croppedImage);
    //    console.log(croppedImage)
        // const blob = await fetch(croppedImage).then((res) => res.blob()); // Convert URL to Blob
        // const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
        // console.log("FILE==========>",file);
    };
    // CROPPING IMAGE

    // CROPPING IMAGE PHOTO
    const [imageSrc2, setImageSrc2] = useState(null);
    const [open2, setOpen2] = useState(false);
    const [croppedImage2, setCroppedImage2] = useState(null);

    const handleFileChange2 = (e) => {
       const file = e.target.files[0];
       const reader = new FileReader();
       reader.onload = () => {
          setImageSrc2(reader.result);
          setOpen2(true);
       };
       reader.readAsDataURL(file);
    };

    const handleCropComplete2 = async (croppedImage2) => {
        setCroppedImage2(croppedImage2);
    //    console.log(croppedImage2)
        
    };
    // CROPPING IMAGE PHOTO
    


    async function formsubmit() {

        setWaitmsg("");

        setMsgcode("");

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // console.log(studentmode);

        var flag=1;

        if (name.length==0) {
            setNameerr("Required Name");
            flag=0;
        }
        if (dob.length==0) {
            setDoberr("Required Date of Birth");
            flag=0;
        }
        if (phone.length<10) {
            setPhoneerr("Required Valid Phone Number");
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setEmailerr("Required Valid Email ID");
            flag=0;
        }
        if (city.length==0) {
            setCityerr("Required City");
            flag=0;
        }
        if (isquali==false) {
            setQualificationerr("Required Qualification");
            flag=0;
        }
        if (studentmode.length=="") {
            setIsstudentmode(true);
            flag=0;
        }
        if (isextra==false) {
            setExtraerr("Required PG Course");
            flag=0;
        }
        if (password.length>0 && password2.length>0) {
            if (password.length<6 || password2.length<6 && password!=password2) {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
            } else if (password.length>=6 && password2.length>=6 && password!=password2) {
                setPassworderr("Password does not match");
                flag=0;
            }
        } else {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
        }

        if (croppedImage==null) {
            setDocerr("Required");
            flag=0;
        } else {
            setDocerr("");
        }

        if (croppedImage2==null) {
            setImgerr("Required");
            flag=0;
        } else {
            setImgerr("");
        }

        if (flag==1) {
            setWaitmsg("Please Wait....");

            // setProgress(progress + 200);

            setLoader(true);
            
            setIsbtn(true);


            const blob = await fetch(croppedImage).then((res) => res.blob()); // Convert URL to Blob
            const pdffile = new File([blob], 'document.jpg', { type: 'image/jpeg' });
            // console.log("FILE==========>",file);

            const blob2 = await fetch(croppedImage2).then((res) => res.blob()); // Convert URL to Blob
            const img = new File([blob2], 'photo.jpg', { type: 'image/jpeg' });
            // console.log("PHOTO==========>",file2);

            var fd=new FormData();
            fd.append("name",name);
            fd.append("dob",dob);
            fd.append("phone",phone);
            fd.append("email",email);
            fd.append("city",city);
            fd.append("qualification",qualification);
            fd.append("mapassingyr",mapassingyr);
            fd.append("extra",extra);
            fd.append("extrapassingyr",extrapassingyr);
            fd.append("password",password);
            fd.append("pdf",pdffile);
            fd.append("img",img);
            // fd.append("img",img);
            fd.append("croppedImage",croppedImage);
            fd.append("croppedImage2",croppedImage2);
            fd.append("studentmode",studentmode);
            // var resp=await axios.post({baseurl}+"/api/studregister",fd);
            // if (studentmode==1) {
            //     var resp=await axios.post("https://www.safalata.in/safalatalara/api/studregister",fd);
            //     var data=resp.data;
            // } else if (studentmode==2) {
            //     var resp=await axios.post("https://www.safalata.co.in/safalatalara/api/studregister",fd);
            //     var data=resp.data;
            // }
            var resp=await axios.post("https://www.safalata.in/safalatalara/api/studregister_new",fd);
            var data=resp.data;


            // alert(data.sz); 
            // alert(data.msgcode);
            setWaitmsg("");

            if (data.msgcode==1) {
                setMsg(data.msg);
                setMsgcode(data.msgcode);
                setMsgcolor(data.msgcolor);

                setIsbtn(true);
                setIscheck(true);

                setName("");
                setDob("");
                setPhone("");
                setEmail("");
                setCity("");
                setQualification("");
                setExtra("");
                setPassword("");
                setPassword2("");
                setNameerr("");
                setDoberr("");
                setPhoneerr("");
                setEmailerr("");
                setCityerr("");
                setQualificationerr("");
                setExtraerr("");
                setPassworderr("");
                setDocerr("");
                setImgerr("");
                setIsstudentmode(false);

                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });

            } else if (data.msgcode==2) {
                setMsg(data.msg);
                setMsgcode(data.msgcode);
                setMsgcolor(data.msgcolor);
                setIsbtn(false);
            } else if (data.msgcode==3) {
                setMsg(data.msg);
                setMsgcode(data.msgcode);
                setMsgcolor(data.msgcolor);

                alert(data.msg);
                setIsbtn(false);
            }

            setLoader(false);
        }

    }

    return(
        <>
        <Header/>
        {/* <Heroslider/> */}
        <main id="main">

            {loader?
                <LoadingBar
                color='#0e00d1'
                progress={progress}
                transitionTime={20000}
                loaderSpeed={20000}
                waitingTime={20000}
                onLoaderFinished={()=>setProgress(0)}
                />
                :
                ''
            }

        <section id="breadcrumbs" className="breadcrumbs" style={mgtop}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Register</h2>
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>Register</li>
                </ol>
                </div>

            </div>
        </section>

        <section id="about-us" className="about-us">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Student Registration</h2>
                </div>

                <div className="row content">
                    <div className="col-lg-1 pt-4 pt-lg-0"></div>
                    <div className="col-lg-10 pt-4 pt-lg-0" data-aos="fade-left">

                        <div className="row" style={{marginBottom: 40, padding: 20, borderRadius: '5px'}}>
                            
                            {waitmsg.length>1?
                            <div className="col-md-12 form-group text-center text-danger" style={{marginTop: 40, marginBottom: 30, padding: 10}}>
                                <div style={{fontSize: 'x-large', fontWeight: 700}} className={{color: '#fc0303'}}>
                                    {waitmsg}
                                </div>
                            </div>
                            :''}

                            {msgcode>0?
                            <div className="col-md-12 form-group text-center" style={{marginTop: 40, marginBottom: 30, padding: 10}}>
                                <div style={{fontSize: 'x-large', fontWeight: 700}} className={msgcolor}>{msg} <br/> 
                                {msgcode==1?<button class="btn btn-primary" onClick={()=>{
                                    window.open("https://studportal.safalata.in/");
                                }} style={{color: '#c70000'}}>Click Here To Login</button>:''}
                                </div>
                            </div>
                            :''}

                            <h5 className="card-title d-none" style={{fontSize: 'x-large'}}>Examination Mode Section</h5>
                            
                            
                            <div className="col-md-12 form-group d-none">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Student Examination Mode — <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="student_mode" id="student_mode" onChange={(ev)=>{
                                        setStudentmode(ev.target.value);
                                        setIsstudentmode(false);
                                    }} value="1" checked />
                                    <label className="form-check-label" for="student_mode">
                                        LIVE CLASS + LIVE EXAM + PRACTICE EXAM
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="student_mode" id="student_mode2" onChange={(ev)=>{
                                        setStudentmode(ev.target.value);
                                        setIsstudentmode(false);
                                    }} value="2" />
                                    <label className="form-check-label" for="student_mode2">
                                        LIVE EXAM + PRACTICE EXAM ONLY
                                    </label>
                                </div>                                
                                {isstudentmode==true?<div className='text text-danger'>CHOOSE ANY ONE MODE AS PER YOUR REQUIRED</div>:''}
                            </div>
                        </div>

                        <div className="row" style={{marginBottom: 40, border: '1px solid #999', padding: 20, borderRadius: '5px'}}>
                            <h5 className="card-title" style={{fontSize: 'x-large'}}>Information as per Educational Records</h5>
                            
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Student name (In full) <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="text" className="form-control form-control-lg" onChange={(ev)=>{
                                    setName(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setNameerr("");
                                    }
                                }} />
                                {name.length==0?<div className='text text-danger'>{nameerr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Date of Birth <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="date" className="form-control form-control-lg" onChange={(ev)=>{
                                    setDob(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setDoberr("");
                                    }
                                }} />
                                {dob.length==0?<div className='text text-danger'>{doberr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Mobile Number <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="tel" maxLength={10} className="form-control form-control-lg" onChange={(ev)=>{
                                    setPhone(ev.target.value);
                                    if (ev.target.value.length==10) {
                                        setPhoneerr("");
                                    }
                                }} />
                                {phone.length<10?<div className='text text-danger'>{phoneerr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Email ID <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="email" className="form-control form-control-lg" onChange={(ev)=>{
                                    setEmail(ev.target.value);
                                    if (validator.isEmail(email)) {
                                        setEmailerr("");
                                    }                                    
                                }} />
                                {!validator.isEmail(email)?<div className='text text-danger'>{emailerr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>City/Locality/Area <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="city" className="form-control form-control-lg" onChange={(ev)=>{
                                    setCity(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setCityerr("");
                                    }
                                }} />
                                {city.length==0?<div className='text text-danger'>{cityerr}</div>:''}
                            </div>

                            <hr style={{marginTop: 20}} />
                            <h5 className="card-title" style={{fontSize: 'x-large', marginTop: 10}}>Educational Qualification</h5>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>M.A. in Hindi/English with Hindi and English as an Honours Paper/Elective Paper/Medium of studies at Degree(B.A.) Level — <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="qualification" id="qualification" onChange={(ev)=>{
                                        setQualification(ev.target.value);
                                        setIsquali(true);
                                        setMapassingyrlabel("Month & Year of Passing");
                                        setIsmapassing(false);
                                    }} value="1" />
                                    <label className="form-check-label" for="qualification">
                                        Completed
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="qualification" id="qualification2" onChange={(ev)=>{
                                        setQualification(ev.target.value);
                                        setIsquali(true);
                                        setMapassingyrlabel("Month & Year of Completion");
                                        setIsmapassing(false);
                                    }} value="2" />
                                    <label className="form-check-label" for="qualification2">
                                        Persuring
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="qualification" id="qualification3" onChange={(ev)=>{
                                        setQualification(ev.target.value);
                                        setIsquali(true);
                                        setMapassingyrlabel("");
                                        setIsmapassing(true);
                                        setMapassingyr("");
                                    }} value="3" />
                                    <label className="form-check-label" for="qualification3">
                                        Others
                                    </label>
                                </div>
                                <div className="form-check d-none" style={{fontSize: 'large'}}>
                                    <label className="form-label" for="qualification3">
                                        {mapassingyrlabel}
                                    </label>
                                    <input className="form-control" type="text" onChange={(ev)=>{
                                        setMapassingyr(ev.target.value);
                                    }} disabled={ismapassing} placeholder="mm/yyyy [e.g. 10/2017]" />
                                    
                                </div>
                                {isquali==false?<div className='text text-danger'>{qualificationerr}</div>:''}
                            </div>

                            <hr style={{marginTop: 20}} />
                            <h5 className="card-title" style={{fontSize: 'x-large', marginTop: 10}}>PG Diploma</h5>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Post Graduation Diploma in Translation (P.G.D.T.) — <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="extra" id="extra" onChange={(ev)=>{
                                        setExtra(ev.target.value);
                                        setIsextra(true);
                                        setExtrapassingyrlabel("Month & Year of Passing");
                                        setIsextrapassing(false);
                                    }} value="1" />
                                    <label className="form-check-label" for="extra">
                                        Completed
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="extra" id="extra2" onChange={(ev)=>{
                                        setExtra(ev.target.value);
                                        setIsextra(true);
                                        setExtrapassingyrlabel("Month & Year of Completion");
                                        setIsextrapassing(false);
                                    }} value="2" />
                                    <label className="form-check-label" for="extra2">
                                        Persuring
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="extra" id="extra3" onChange={(ev)=>{
                                        setExtra(ev.target.value);
                                        setIsextra(true);
                                        setExtrapassingyrlabel("");
                                        setIsextrapassing(true);
                                        setExtrapassingyr("");
                                    }} value="3" />
                                    <label className="form-check-label" for="extra3">
                                        Others
                                    </label>
                                </div>
                                <div className="form-check d-none" style={{fontSize: 'large'}}>
                                    <label className="form-label">
                                        {extrapassingyrlabel}
                                    </label>
                                    <input className="form-control" type="text" onChange={(ev)=>{
                                        setExtrapassingyr(ev.target.value);
                                    }} disabled={isextrapassing} placeholder="mm/yyyy [e.g. 10/2017]" />
                                    
                                </div>
                                {isextra==false?<div className='text text-danger'>{extraerr}</div>:''}
                            </div>
                            <hr style={{marginTop: 20}} />
                            <h5 className="card-title" style={{fontSize: 'x-large', marginTop: 10}}>Create Password</h5>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>New Password <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                    <input type="password" className="form-control form-control-lg" onChange={(ev)=>{
                                        setPassword(ev.target.value);
                                        if (ev.target.value.length>=6 && password2.length>=6) {
                                            setPassworderr("");
                                        }
                                    }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Retype New Password <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                    <input type="password" className="form-control form-control-lg" onChange={(ev)=>{
                                        setPassword2(ev.target.value);
                                        if (ev.target.value.length>=6 && password.length>=6) {
                                            setPassworderr("");
                                        }
                                    }} />
                                    <div className='text text-danger'>{passworderr}</div>
                            </div>

                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 0}}>Upload Identity Proof (Only .jpg, jpeg, .png image) <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <label for="name" style={{fontSize: 'small', fontWeight: 700, marginBottom: 20}}>[ Only aadhaar card photo copy proof ]</label>
                                    {/* <input type="file" className="form-control form-control-lg" onChange={(ev)=>{
                                        setDoc(ev.target.files[0]);
                                    }} accept='.jpg,.jpeg' /> */}
                                    <input type='file' id="image" className='photo form-control' accept="image/*" onChange={handleFileChange}/>
                                    {croppedImage && <img src={croppedImage} alt="Cropped" class="img-fluid w-25" style={{ marginTop: 20, width: '100%' }} />}
                                    <ImageCropModal
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    imageSrc={imageSrc}
                                    onCropComplete={handleCropComplete}
                                    />
                                    <div className='text text-danger'>{docerr}</div>
                            </div>

                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 0}}>Upload Profile Pic (Only .jpg, jpeg image) <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <label for="name" style={{fontSize: 'small', fontWeight: 700, marginBottom: 20}}>[ The size of image must be below 1 MB ]</label>
                                    {/* <input type="file" className="form-control form-control-lg" onChange={(ev)=>{
                                        setImg(ev.target.files[0]);
                                    }} accept='.jpg,.jpeg' /> */}
                                    <input type='file' id="photo" className='photo form-control' accept="image/*" onChange={handleFileChange2}/>
                                    {croppedImage2 && <img src={croppedImage2} alt="Cropped" class="img-fluid w-25" style={{ marginTop: 20, width: '100%' }} />}
                                    <ImageCropModal
                                    open={open2}
                                    onClose={() => setOpen2(false)}
                                    imageSrc={imageSrc2}
                                    onCropComplete={handleCropComplete2}
                                    />
                                    <div className='text text-danger'>{imgerr}</div>
                            </div>

                            <div className="form-check" style={{marginTop: 20}}>
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(ev)=>{
                                    if (ev.target.checked==true) {
                                        setIsbtn(false);
                                    } else {
                                        setIsbtn(true);
                                    }
                                }} disabled={ischeck} />
                                <label className="form-check-label" for="flexCheckDefault">
                                    {/* I hereby declare that all the above information is correct and accurate. I solemnly declare that all the information furnished in this document is free of errors to the best of my knowledge and also I have read and agree to the terms of use. */}
                                    मैं यह घोषणा करता हूँ/करती हूँ कि "सफलता" संस्थान में अध्ययन करने के दौरान यदि मैं कहीं परीक्षा देता/देती हूँ  और परीक्षा में सफल होता/होती हूँ तो इसका श्रेय "सफलता" संस्थान को जाएगा।
                                </label>
                            </div>

                            <div className="col-md-12 form-group text-center" style={{marginTop: 40}}>
                                <input type="button" className="btn btn-success" value="REGISTER" disabled={isbtn} style={{fontSize: 'x-large'}} onClick={formsubmit} />
                            </div>
                            
                            {msgcode>0?
                            <div className="col-md-12 form-group text-center" style={{marginTop: 40}}>
                                <div style={{fontSize: 'large', fontWeight: 700}} className={msgcolor}>{msg} <br/> 
                                {msgcode==1?<button class="btn btn-primary" onClick={()=>{
                                    window.open("https://studportal.safalata.in/")
                                }} style={{color: '#c70000'}}>Click Here To Login</button>:''}
                                </div>
                            </div>
                            :''}

                        </div>
                    </div>
                    <div className="col-lg-1 pt-4 pt-lg-0"></div>
                </div>


            </div>
        </section>

        

        </main>
        <Footer/>
        </>
    );
}
export default Register;